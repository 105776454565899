import { Directive } from '@angular/core';
import { CompanyUpdateService } from './services/company-update.service';
import {
  Company,
  ICompanyUpdate,
  EditItemType,
  getRelationshipByPropertyName,
  DiversityOption,
} from 'company-finder-common';
import { ComponentBase } from '../../_common/components/_component.base';
import { ReviewableUpdate, SelfUpdateMode } from './company-update.interface';
import { CompanyService } from '../../_common/services/company/company.service';
import { DeploymentContext } from '../../_common/utilities/deployment-context/deployment-context';

@Directive()
export abstract class UpdateComponentBase extends ComponentBase {
  constructor(
    dc: DeploymentContext,
    public _companyUpdateService: CompanyUpdateService,
    public _companyService: CompanyService
  ) {
    super(dc);
  }

  // Expose Enums so they can be used directly in templates
  public SelfUpdateModes = SelfUpdateMode;
  public EditItemType = EditItemType;

  // Similarly, the service calls really clutter up the templates.
  // Rather than make them there, give all update components access
  // to the common ones.
  public get company(): Company {
    return this._companyUpdateService.company;
  }

  public set company(value: Company) {
    this._companyUpdateService.company = value;
  }

  public get companyBeforeAnyChanges(): Company {
    return this._companyUpdateService.companyBeforeAnyChanges;
  }

  public set companyBeforeAnyChanges(value: Company) {
    this._companyUpdateService.companyBeforeAnyChanges = value;
  }

  public get companyClone(): Company {
    return this._companyUpdateService.companyClone;
  }

  public set companyClone(value: Company) {
    this._companyUpdateService.companyClone = value;
  }

  public get companyWithPending(): Company {
    return this._companyUpdateService.companyWithPending;
  }

  public set companyWithPending(value: Company) {
    this._companyUpdateService.companyWithPending = value;
  }
  public get companyWithMostRecentEdit(): Company {
    return this._companyUpdateService.companyWithMostRecentEdit;
  }

  public set companyWithMostRecentEdit(value: Company) {
    this._companyUpdateService.companyWithMostRecentEdit = value;
  }

  public get isEditingProperty(): { [propName: string]: boolean } {
    return this._companyUpdateService.isEditingProperty;
  }

  public get updateForReview(): ReviewableUpdate {
    return this._companyUpdateService.updateForReview;
  }

  public set updateForReview(value: ReviewableUpdate) {
    this._companyUpdateService.updateForReview = value;
  }

  public get reviewComments(): string {
    return this._companyUpdateService.reviewComments;
  }

  public set reviewComments(value: string) {
    this._companyUpdateService.reviewComments = value;
  }

  public get selfUpdateMode(): SelfUpdateMode {
    return this._companyUpdateService.selfUpdateMode;
  }

  public set selfUpdateMode(value: SelfUpdateMode) {
    this._companyUpdateService.selfUpdateMode = value;
  }

  public get iEditMode(): boolean {
    return this._companyUpdateService.selfUpdateMode === SelfUpdateMode.Edit;
  }

  public get iReviewMode(): boolean {
    return this._companyUpdateService.selfUpdateMode === SelfUpdateMode.Review;
  }

  public get haveDealsChanged(): boolean {
    return this._companyUpdateService.haveDealsChanged;
  }

  public set haveDealsChanged(value: boolean) {
    this._companyUpdateService.haveDealsChanged = value;
  }

  public get haveFundingsChanged(): boolean {
    return this._companyUpdateService.haveFundingsChanged;
  }

  public set haveFundingsChanged(value: boolean) {
    this._companyUpdateService.haveFundingsChanged = value;
  }

  public get isShareWithFollowers(): boolean {
    return this._companyUpdateService.isShareWithFollowers;
  }

  public set isShareWithFollowers(value: boolean) {
    this._companyUpdateService.isShareWithFollowers = value;
  }

  public isUpdateFieldSet(field: string): boolean {
    return this._companyUpdateService.isUpdateFieldSet(field);
  }

  public get isProgressUpdateSet(): boolean {
    return this._companyUpdateService.isProgressUpdateSet();
  }

  public get pendingUpdate(): ICompanyUpdate {
    return this._companyUpdateService.pendingUpdate;
  }

  public set pendingUpdate(value: ICompanyUpdate) {
    this._companyUpdateService.pendingUpdate = value;
  }

  protected getRelatedPropertyNamesFor(propertyName: string): string[] {
    // FUTURE - This should likelyfmove to metadata
    const relationship = getRelationshipByPropertyName(
      this._deploymentContext.companyUpdatePropertyRelationships,
      propertyName
    );

    if (!relationship) {
      return [];
    }

    const relatedProperties = [
      relationship.key,
      ...relationship.relatedProperties,
    ];
    return relatedProperties.filter((p) => p !== propertyName);
  }

  // Note: These values need to be kept in sync with the configuration data. If it changes,
  // for example, to just 'Other' then our code breaks. We could, instead, rely on
  // assumed positioning (second to last and last), but there's no simple way to add
  // a comment to the JSON file reminding maintainers to honor that contract.
  protected OTHER_COUNTRY_STRING = 'Other Country';
  protected ALL_COUNTRIES_STRING = 'All Countries';
  protected getDiversityOptionsForCountry(
    countryForDeiReporting: string
  ): DiversityOption[] {
    if (!countryForDeiReporting) {
      return undefined;
    }

    const deiCountriesAndOptions =
      this._deploymentContext.referenceValueData.deiCountriesAndOptionsList;

    let deiConfig = deiCountriesAndOptions.find(
      (item) => item.label === countryForDeiReporting
    );

    if (!deiConfig) {
      // Couldn't find a set for the specific country, so fall back on the "Other Country" set
      deiConfig = deiCountriesAndOptions.find(
        (item) => item.label === this.OTHER_COUNTRY_STRING // See comment below regarding OTHER_COUNTRY_STRING
      );
    }

    // Get the options that should be included for all countries
    const allCountriesDeiConfig = deiCountriesAndOptions.find(
      (item) => item.label === this.ALL_COUNTRIES_STRING // See comment below regarding ALL_COUNTRIES_STRING
    );

    // Return the combination of the two sets
    return deiConfig?.diversityOptions.concat(
      allCountriesDeiConfig?.diversityOptions
    );
  }
}
