import { Subject, Subscription, fromEvent } from 'rxjs';

import { Logger } from '../utilities/logger/logger';
import { Directive, OnDestroy } from '@angular/core';
import { DeploymentContext } from '../utilities/deployment-context/deployment-context';
import {
  Company,
  CompanyKey,
  FeatureSwitches,
  LocPrefix,
  LocalizedTextIds,
  ThemeSettings,
  ValueWithHeader,
} from 'company-finder-common';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { iframeResizer } from '@iframe-resizer/child';

@Directive()
export abstract class ComponentBase implements OnDestroy {
  // protected properties
  protected _logger = new Logger(this.constructor.name);
  protected _subscriptions: Subscription;
  protected _deploymentContext: DeploymentContext;
  protected _windowSubscriptions: Subscription[] = [];

  private static breakpointMatch: boolean = null;
  private static breakpointObserver: BreakpointObserver;

  protected get narrowScreen(): boolean {
    if (ComponentBase.breakpointMatch === null) {
      throw new Error('Breakpoint not registered');
    }
    return ComponentBase.breakpointMatch;
  }

  protected get iframe(): iframeResizer.IFramePage {
    return this._deploymentContext.iframe;
  }

  public resizerEnabled = true;

  private toggleResizer(state: boolean) {
    if (this.iframe && this.resizerEnabled !== state) {
      this.iframe.autoResize(state);
      this.resizerEnabled = state;
    }
  }

  protected enableResizer(): void {
    this.toggleResizer(true);
  }

  protected disableResizer(): void {
    this.toggleResizer(false);
  }

  public constructor(dc: DeploymentContext) {
    this._deploymentContext = dc;
  }

  ngOnDestroy(): void {
    this.enableResizer();
  }

  // If an *ngFor loop does not have a unique identifier for each object in the array,
  // it may lose the binding between the DOM element and the object during changes
  // to the DOM. To avoid this, we provide a custom TrackBy function for the objects
  // to ensure a stable unique identifier for each one.
  public trackBy(index: number, _prop: unknown): number {
    return index;
  }

  // protected methods
  protected addSubscription(sub: Subscription): void {
    if (this._subscriptions) {
      this._subscriptions.add(sub);
    } else {
      this._subscriptions = sub;
    }
  }

  protected unsubscribe(): void {
    if (this._subscriptions) {
      this._subscriptions.unsubscribe();
      this._subscriptions = undefined;
    }
  }

  protected registerNarrowScreenBreakpoint(
    breakpointObserver: BreakpointObserver
  ): void {
    if (this._deploymentContext.debug.alwaysUseNarrowScreen) {
      ComponentBase.breakpointMatch = true;
      return;
    }

    if (ComponentBase.breakpointObserver) {
      return;
    }

    ComponentBase.breakpointObserver = breakpointObserver;
    ComponentBase.breakpointObserver
      .observe([
        `(max-width: ${this._deploymentContext.narrowScreenBreakpoint})`,
      ])
      .subscribe((state: BreakpointState) => {
        ComponentBase.breakpointMatch = state.matches;
      });
  }

  // If the given Subject has no subscriptions, add the handler provided and register with this component.
  // Assumes only one observer is expected for the subject.  If that is not the case, do not use this method.
  protected addSubscriptionHandlerIfNeeded<T>(
    subject: Subject<T>,
    handler: (payload: T) => void
  ): void {
    if (subject.observers.length === 0) {
      this.addSubscription(subject.subscribe(handler));
    }
  }

  public get featureSwitches(): FeatureSwitches {
    return this._deploymentContext.featureSwitches;
  }

  public get themeSettings(): ThemeSettings {
    return this._deploymentContext.themeSettings;
  }

  public getValueWithHeader(
    propertyName: CompanyKey,
    company?: Company
  ): ValueWithHeader {
    return this._deploymentContext.getValueWithHeader(propertyName, company);
  }

  public getHeaderForProperty(propertyName: CompanyKey): string {
    return this._deploymentContext.getHeaderForProperty(propertyName);
  }

  public get LocalizedTextIds(): typeof LocalizedTextIds {
    return LocalizedTextIds;
  }

  public get LocPrefix(): typeof LocPrefix {
    return LocPrefix;
  }

  public Loc(
    key: string,
    ...replacements: (string | number | boolean)[]
  ): string {
    return this._deploymentContext.Loc(key, ...replacements);
  }

  public LocPluralize(
    key: string,
    pluralNumber: number,
    ...replacements: (string | number | boolean)[]
  ): string {
    return this._deploymentContext.LocPluralize(
      key,
      pluralNumber,
      ...replacements
    );
  }

  public LocWithPrefix(
    key: string,
    prefix: string,
    tryWithNoPrefix: boolean = false
  ): string {
    return this._deploymentContext.LocWithPrefix(key, prefix, tryWithNoPrefix);
  }

  public LocDbValue(key: string, tryWithNoPrefix: boolean = false): string {
    return this.LocWithPrefix(key, LocPrefix.Db, tryWithNoPrefix);
  }

  public IsNumberOrNumberString(value: unknown): boolean {
    return !isNaN(parseFloat(value as string)) && isFinite(value as number);
  }

  private static lambdasForCDAndDo;

  // Used for triggering change detection when working with angular lifecycle methods.
  // Prevents ExpressionChangedAfterItHasBeenCheckedError when changing properties during the Angular lifecycle.
  // Only one setTimeout() is created to process all the callbacks registered since
  // the last timeout fired, as an optimization since we sometimes have many components
  // showing which are all using this, and it get very slow if each has its own setTimeout().
  public triggerCDAndDo(callback: () => void): void {
    if (!ComponentBase.lambdasForCDAndDo) {
      ComponentBase.lambdasForCDAndDo = [];
      setTimeout(() => {
        while (ComponentBase.lambdasForCDAndDo.length) {
          const lambda = ComponentBase.lambdasForCDAndDo.pop();
          lambda();
        }
        ComponentBase.lambdasForCDAndDo = undefined;
      }, 0);
    }
    ComponentBase.lambdasForCDAndDo.push(callback);
  }

  protected registerWindowHandlerSubscription(
    eventName: string,
    handler: (event: Event) => void
  ): void {
    if (!this._windowSubscriptions[eventName]) {
      setTimeout(() => {
        this._windowSubscriptions[eventName] = fromEvent(
          window,
          eventName
        ).subscribe((ev) => {
          setTimeout(() => {
            handler(ev);
          });
        });
      });
    }
  }
  protected unregisterWindowHandlerSubscription(eventName: string): void {
    if (this._windowSubscriptions[eventName]) {
      this._windowSubscriptions[eventName].unsubscribe();
      this._windowSubscriptions[eventName] = undefined;
    }
  }
}
