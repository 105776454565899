import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

import _ from 'lodash';
import {
  LocationWithCountAndCanonicalString,
  SelectionEventData,
} from 'company-finder-common';
import { SearchService } from '../../../../_common/services/search/search.service';
import { PickerModalBaseComponent } from '../../../../_common/components/picker-modal/picker-modal-base.component';
import { UserService } from '../../../../_common/services/user/user.service';
import { DeploymentContext } from '../../../../_common/utilities/deployment-context/deployment-context';
import { addLocationsToUser } from '../../../../_common/utilities/preferences/preferences.util';

@Component({
  selector: 'locations-picker-modal',
  templateUrl: './locations-picker-modal.component.html',
  styleUrls: ['./locations-picker-modal.component.scss'],
  // We want to allow the template to have access to styling we put on the outer component (like .selected),
  // so turn off encapsulation.  We'll be careful not to cause style conflicts.
  encapsulation: ViewEncapsulation.None,
})
export class LocationsPickerModalComponent
  extends PickerModalBaseComponent<LocationWithCountAndCanonicalString>
  implements OnInit
{
  private allLocationsWithCounts: LocationWithCountAndCanonicalString[] = [];

  @Input()
  public isOnBoardingWizard: boolean;
  @Input()
  public totalMatchedCompanies: number;

  public constructor(
    dc: DeploymentContext,
    protected _userService: UserService
  ) {
    super(dc, _userService);
  }

  public async ngOnInit(): Promise<void> {
    // Cache locations/counts with canonical representation for more efficient searching

    const summary = this._deploymentContext.comprehensiveSummary;
    this.allLocationsWithCounts = _.sortBy(
      summary.locations.map((location) => ({
        location: location,
        count: summary.getNumberOfCompaniesAtLocation(location.name),
        canonicalString: location.name.toLowerCase(),
      })),
      (locationEntry) => locationEntry.location.name
    );
  }

  public async doSearch(
    newSearchText: string
  ): Promise<LocationWithCountAndCanonicalString[]> {
    const newSearchTextToLower = newSearchText.toLowerCase();

    const isMatch = (lwc: LocationWithCountAndCanonicalString): boolean =>
      lwc.canonicalString?.includes(newSearchTextToLower);

    if (newSearchText.length === 0) {
      // Only filter down if a filter text was given
      return this.allLocationsWithCounts;
    }

    return _.filter(this.allLocationsWithCounts, isMatch);
  }

  public async doSelect(selectionEventData: SelectionEventData): Promise<void> {
    // This logic is to update the "live" count for the wizard,
    // no need to make API calls otherwise
    if (!this.isOnBoardingWizard) {
      return;
    }

    const wizardUser = _.cloneDeep(this.user);

    addLocationsToUser(selectionEventData.selectedItems, wizardUser);
    this.updateCompanyCountForWizard(wizardUser);
  }
}
