import { Component, Input, OnInit } from '@angular/core';

import { ComponentBase } from '../../../../_common/components/_component.base';

// model imports
import { getSectorSubsectorSynonyms } from 'company-finder-common';

// utility imports
import { SearchService } from '../../../../_common/services/search/search.service';
import { WebAnalyticsService } from '../../../../_common/services/web-analytics/web.analytics';
import { DeploymentContext } from '../../../../_common/utilities/deployment-context/deployment-context';
import { Summary } from '../../../../_common/utilities/summary/summary';

enum DrilldownType {
  Count,
  Sector,
  CrossSector,
  New,
  Status,
  BlueKnight,
  QfcWinner,
}

@Component({
  selector: 'summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
})
export class SummaryComponent extends ComponentBase implements OnInit {
  // public properties
  public comprehensiveSummary: Summary;
  @Input()
  public summary: Summary;
  public isShowingTotalTooltip = false;
  public isShowingTileTooltip = false;
  public DrilldownTypes = DrilldownType;
  public DrilldownType: DrilldownType = DrilldownType.Count;

  public constructor(
    dc: DeploymentContext,
    private _searchService: SearchService,
    private _webAnalyticsService: WebAnalyticsService
  ) {
    super(dc);
  }

  async ngOnInit(): Promise<void> {
    this.comprehensiveSummary =
      this._deploymentContext.comprehensiveSummary;
  }

  // public getters
  public get currentCompanyCountDisplay(): string {
    return `${this.summary.numberOfCompanies}`;
  }

  public get totalCompaniesCount(): number {
    return this.comprehensiveSummary?.numberOfCompanies;
  }

  public get totalCompaniesCountDisplay(): string {
    return `${this.totalCompaniesCount}`;
  }

  public get showingAllCompanies(): boolean {
    if (this.summary.numberOfCompanies === this.totalCompaniesCount) {
      return true;
    }
    return;
  }

  public get shouldShowSummary(): boolean {
    return (
      this.summary &&
      (this.featureSwitches.enableSectors ||
        this.shouldShowBlueKnightCount ||
        this.shouldShowQfcCount)
    );
  }

  public get shouldShowNewCompanyCount(): boolean {
    return (
      this.featureSwitches.enableShowNewCompanies &&
      !this.shouldShowBlueKnightCount &&
      this.comprehensiveSummary &&
      this.comprehensiveSummary.numberOfNewCompanies > 0
    );
  }

  public get shouldShowShowStatusCount(): boolean {
    return this.featureSwitches.enableShowLocationStatus;
  }

  public get numberOfResidentsForStatusTile(): number {
    return this.summary.numberOfResidentsForStatusTile;
  }

  public get shouldShowQfcCount(): boolean {
    return this.featureSwitches.enableQfc;
  }

  public get shouldShowBlueKnightCount(): boolean {
    return (
      this.featureSwitches.enableBlueKnight &&
      this.comprehensiveSummary?.numberOfBlueKnights > 0
    );
  }

  public get shouldShowRightSeparator(): boolean {
    return (
      this.shouldShowNewCompanyCount ||
      this.shouldShowBlueKnightCount ||
      this.shouldShowQfcCount ||
      this.shouldShowShowStatusCount
    );
  }

  public get shouldShowCrossSector(): boolean {
    return this.summary.numberOfCrossSectorCompanies > 0;
  }

  public get shouldShowRightContainer(): boolean {
    return (
      this.shouldShowNewCompanyCount ||
      this.shouldShowShowStatusCount ||
      this.shouldShowQfcCount ||
      this.shouldShowBlueKnightCount
    );
  }

  // public methods
  public showTotalTooltip(_event: MouseEvent): void {
    this.isShowingTotalTooltip = true;
  }

  public hideTotalTooltip(_event: MouseEvent): void {
    this.isShowingTotalTooltip = false;
  }

  public showTileTooltip(_event: MouseEvent): void {
    this.isShowingTileTooltip = this._deploymentContext.tileTooltip?.length > 0;
  }

  public hideTileTooltip(_event: MouseEvent): void {
    this.isShowingTileTooltip = false;
  }
  public get tileLabel(): string {
    return this._deploymentContext.tileLabel;
  }

  public get tileTooltip(): string {
    return this._deploymentContext.tileTooltip;
  }

  public async handleClick(
    drilldownType: DrilldownType,
    sector: string = null
  ): Promise<void> {
    const filter = this._searchService.filter;
    switch (drilldownType) {
      case DrilldownType.Count:
        // FUTURE: This was a quick hack related to ADJQ-210.  There could definitely be a cleaner way of
        // indicating that we should *always* transition to the search results in this case.
        this._searchService.currentSearchPredicate = ' ';
        break;
      case DrilldownType.Sector:
        filter.primarySectors = getSectorSubsectorSynonyms(sector);
        break;
      case DrilldownType.CrossSector:
        filter.isCrossSector = true;
        break;
      case DrilldownType.New:
        filter.isNewInLastQuarter = true;
        break;
      case DrilldownType.Status:
        const statusMeta = this._deploymentContext.tileMetaData;
        if (statusMeta) {
          switch (statusMeta.statusType) {
            case 'location':
              filter.locationStatuses = this._deploymentContext.statusForTile;
              break;
            case 'company':
              filter.companyStatuses = this._deploymentContext.statusForTile;
              break;
          }
        }
        break;
      case DrilldownType.BlueKnight:
        filter.isBlueKnight = true;
        break;
      case DrilldownType.QfcWinner:
        filter.isQFCWinner = true;
        break;
    }

    this._searchService.drilldownSubject.next(filter);

    this._webAnalyticsService.trackEvent('summary-drilldown', {
      label:
        drilldownType === DrilldownType.Sector
          ? sector
          : DrilldownType[drilldownType],
      // value: stageCount.count // FUTURE: There is no easy way to get this without replicating display logic
    });
  }
}
