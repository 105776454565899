import { Directive, Input, QueryList, ViewChildren } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UpdateComponentBase } from './UpdateComponentBase';
import { EditItemComponent } from './components/edit-item/edit-item.component';
import {
  Deal,
  DiversityOption,
  EditItemType,
  Funding,
} from 'company-finder-common';
import { MultiChoiceItemChange } from './company-update.interface';
import { EditItemGroupComponent } from './components/edit-item-group/edit-item-group.component';

@Directive()
export abstract class UpdateComponentBaseWithEditItems extends UpdateComponentBase {
  // The edit components are subscribed to in the main update component, both for
  // any it has and for each tab component. This is a separate base so that
  // the EditItemComponent itself can have all the company items, the current mode, etc
  @ViewChildren(EditItemComponent)
  public editItemComponents: QueryList<EditItemComponent>;

  public get editItemArray(): EditItemComponent[] {
    const groupedItems =
      this.editItemGroupArray?.flatMap((group) => group.editItemArray) ?? [];
    const topLevelItems = this.editItemComponents?.toArray() || [];
    return [...groupedItems, ...topLevelItems];
  }

  @ViewChildren(EditItemGroupComponent)
  public editItemGroupComponents: QueryList<EditItemGroupComponent>;

  public get editItemGroupArray(): EditItemGroupComponent[] {
    return this.editItemGroupComponents?.toArray() || [];
  }

  @Input()
  public companyEditForm: FormGroup;

  // If an *ngFor loop does not have a unique identifier for each object in the array,
  // it may lose the binding between the DOM element and the object during changes
  // to the DOM. To avoid this, we provide a custom TrackBy function for the objects
  // to ensure a stable unique identifier for each one.
  public dealTrackBy(_index: number, deal: Deal): string {
    return deal.dealId;
  }

  public fundingTrackBy(_index: number, funding: Funding): string {
    return funding.fundingId;
  }

  public parseMultipleChoices(
    separatedValueString: string,
    separator = ';'
  ): string[] {
    try {
      // Make sure they are sorted, so the diffs can reliably match sets with the same options.
      return separatedValueString?.split(separator)?.sort() ?? [];
    } catch {
      return [];
    }
  }

  public getSelectedChoices(prop: string): string[] {
    const formControl = this.companyEditForm.get(prop);
    return this.parseMultipleChoices(formControl?.value);
  }

  public setPropertyValue(prop: string, value: string): void {
    const formControl = this.companyEditForm.get(prop);
    formControl?.setValue(value);
  }

  public setAllSubscriptions(): void {
    this.editItemArray.forEach((editItem) => {
      if (editItem.updateOptions?.editType === EditItemType.MultiChoice) {
        this.addSubscription(
          editItem.multiChoiceValueChangedSubject.subscribe(
            async (data: MultiChoiceItemChange) => {
              let selections =
                this.companyEditForm
                  .get(editItem.propertyName)
                  .value?.filter((val) => !!val) ?? [];

              // An empty selection comes as empty string, so fix it as an empty arrray
              if (selections === '') {
                selections = [];
              }

              if (!selections.includes(data.value)) {
                if (this.isMutuallyExclusiveOption(data.value)) {
                  selections.splice(0, selections.length);
                } else {
                  selections = selections.filter((selection) =>
                    this.mutuallyExclusiveOptions.some(
                      (meo) => meo.value !== selection
                    )
                  );
                }

                selections.push(data.value);
              } else {
                selections = selections.filter(
                  (selection) => selection !== data.value
                );
              }

              this.setPropertyValue(editItem.propertyName, selections.sort());
            }
          )
        );
      }
      this.addSubscription(
        // FUTURE - we need to extend this if we are using this behavior on other types
        editItem.constrainedValueChangeSubject.subscribe(async () => {
          this.getRelatedPropertyNamesFor(editItem.propertyName)?.forEach(
            (prop) => {
              this.setPropertyValue(prop, '');
            }
          );
        })
      );
    });
  }

  protected get mutuallyExclusiveOptions(): DiversityOption[] {
    // Currently this is only used for per-country DEI, but this could be expanded
    return this.getDiversityOptionsForCountry(
      this.countryForDeiReporting
    )?.filter((o) => o.isMutuallyExclusive);
  }

  protected isMutuallyExclusiveOption(value: string): boolean {
    return this.mutuallyExclusiveOptions?.some((meo) => meo.value === value);
  }

  public get countryForDeiReporting(): string {
    const formControl = this.companyEditForm.get('countryForDeiReporting');
    return formControl?.value;
  }
}
