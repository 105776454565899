import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

import _ from 'lodash';
import { SearchService } from '../../../../_common/services/search/search.service';
import { SelectionEventData } from 'company-finder-common';
import { SectorWithCountAndCanonicalString } from 'company-finder-common';
import { PickerModalBaseComponent } from '../../../../_common/components/picker-modal/picker-modal-base.component';
import { UserService } from '../../../../_common/services/user/user.service';
import { addSectorsToUser } from '../../../../_common/utilities/preferences/preferences.util';
import { DeploymentContext } from '../../../../_common/utilities/deployment-context/deployment-context';

@Component({
  selector: 'sectors-picker-modal',
  templateUrl: './sectors-picker-modal.component.html',
  styleUrls: ['./sectors-picker-modal.component.scss'],
  // We want to allow the template to have access to styling we put on the outer component (like .selected),
  // so turn off encapsulation.  We'll be careful not to cause style conflicts.
  encapsulation: ViewEncapsulation.None,
})
export class SectorsPickerModalComponent
  extends PickerModalBaseComponent<SectorWithCountAndCanonicalString>
  implements OnInit
{
  private allSectorsWithCounts: SectorWithCountAndCanonicalString[] = [];
  // Keep track of collapsed state of children by the parent
  // The default state is collapsed (not open)
  public openChildOptions: { [id: string]: boolean } = {};

  // Function to provide to picker-modal to control visibility of items (in this case, whether to show subsectors based on an expander)
  public isVisible: (item: SectorWithCountAndCanonicalString) => boolean;

  @Input()
  public isOnBoardingWizard: boolean;

  @Input()
  public totalMatchedCompanies: number;

  public constructor(
    dc: DeploymentContext,
    private _searchService: SearchService,
    protected _userService: UserService
  ) {
    super(dc, _userService);
    // Need to bind the isVisibleInternal function to this instance, and provide that to the parent container
    // Otherwise the function won't have access to instance variables
    this.isVisible = this.isVisibleInternal.bind(this);
  }

  public async ngOnInit(): Promise<void> {
    // Cache sectors/counts with canonical representation for more efficient searching
    this.allSectorsWithCounts = this._deploymentContext.comprehensiveSummary.allSectorsWithCounts;
  }

  public async doSelect(selectionEventData: SelectionEventData): Promise<void> {
    // This logic is to update the "live" count for the wizard,
    // no need to make API calls otherwise
    if (!this.isOnBoardingWizard) {
      return;
    }

    const wizardUser = _.cloneDeep(this.user);
    addSectorsToUser(selectionEventData.selectedItems, wizardUser);
    await this.updateCompanyCountForWizard(wizardUser);
  }

  public async doSearch(
    newSearchText: string
  ): Promise<SectorWithCountAndCanonicalString[]> {
    const newSearchTextToLower = newSearchText.toLowerCase();

    const isMatch = (swc: SectorWithCountAndCanonicalString): boolean => {
      const canonical = swc.canonicalString;
      return canonical && canonical.includes(newSearchTextToLower);
    };

    if (newSearchText.length === 0) {
      // Only filter down if a filter text was given
      return this.allSectorsWithCounts;
    }

    // Desired behavior is that any sector/subsector which matches explicitly is included,
    // as well as the parent of such an included subsector.
    // Do the filter first to determine matches, then do a second filter
    // to include any parent or match
    const matches = _.filter(this.allSectorsWithCounts, isMatch);
    const parentSectorsToInclude = _.groupBy(
      matches,
      (swc) => swc.parentSector
    );
    return _.filter(
      this.allSectorsWithCounts,
      (swc) => isMatch(swc) || !!parentSectorsToInclude[swc.sector]
    );
  }

  // Use of lambda/arrow function intentional to capture "this" in context. With instance methods, "this" no longer
  // referes to the instance when passed as a delegate
  public compareSectors = (
    item1: SectorWithCountAndCanonicalString,
    item2: SectorWithCountAndCanonicalString
  ): boolean =>
    this.compareItemsWithCanonicalString(item1, item2) &&
    this.compareItemsWithCanonicalString(
      item1.parentSector,
      item2.parentSector
    );

  public toggleOpenChildOptions(sectorName: string): void {
    this.openChildOptions[sectorName] = !this.openChildOptions[sectorName];
  }

  private isVisibleInternal(item: SectorWithCountAndCanonicalString) {
    return !item.parentSector || this.openChildOptions[item.parentSector];
  }
}
